import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="split-screen">
        <div className="left-side gap-3">
          <p className='text-3xl font-bold'>
            <b>P</b>ersonalized <b>S</b>ound <b>R</b>evolution
          </p>
          <hr className='border-2 border-white w-1/2' />
          <div>
            <p className='text-3xl'>
              Audio.
            </p>
          </div>
        </div>
        <div className="right-side relative">
          <p className='text-3xl'>when?</p>
          <button className="overlay-button absolute bottom-5 px-4 py-2 border-2 rounded-md text-black border-black bg-white">
            Stay tuned!
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
